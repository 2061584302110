import { createApi} from "@reduxjs/toolkit/query/react";
import useAxiosInterceptors from "../axios/useAxios";

// const BASE_URL = 'http://localhost:80/';
// const BASE_URL = 'https://back2back-rabvfmzrtq-od.a.run.app/' // BACKOFFICE BACKEND TEST
const BASE_URL = process.env.REACT_APP_BACKEND_URL  // BACKOFFICE BACKEND LIVE
// export const axiosInstance = Config()


export const AxiosBaseQuery = () => {
  const axiosInstance = useAxiosInterceptors()
  return async ({ url, method, data}) => {

    if (url && url.length > 0) {
      try {
        const result = await axiosInstance({
          url: url,
          method: method, 
          data: data
        });

        console.log(result)
        return { data: result.data };
      } catch (axiosError) {
        if(axiosError.response.status === 403)
          { 
            window.location.href = "/?ok=true";
            console.log("refresh403")
          }
        if(axiosError.response.status === 401)
        { 
          window.location.href = "/?ok=true";
          console.log("refresh401")
        }
        
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };
};


export const api = createApi({
  baseQuery: AxiosBaseQuery(),

  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Products",
    "Customers",
    "Transactions",
    "Geography",
    "Sales",
    "Admins",
    "Performance",
    "Dashboard",
    "Info",
    "Overall",
    "Stores",
    "ProductsInfo",
    "ScanInfo",
    "DeleteInfo",
    "Orders",
    "Paid",
    "Socket",
    "Auth",
    "Register",
    "Logout",
    "Import",
    "Export",
    "Tab"
  ],
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => ({
        url: `${BASE_URL}general/user/${id}`,
        method: "GET",
      }),
      providesTags: ["User"],
    }),
    // getProducts: build.query({
    //   query: (storeId) => ({
    //     url: `${BASE_URL}client/products/${storeId}`,
    //     method: "GET",
    //   }),
    //   providesTags: ["Products"],
    // }),
    getCustomers: build.query({
      query: () => ({
        url: `${BASE_URL}client/customers`,
        method: "GET",
      }),
      providesTags: ["Customers"],
    }),
    getTransactions: build.query({
      query: ({ storeId, searchInput }) => ({
        url: `${BASE_URL}client/transactions/${storeId}`,
        method: "GET",
        params: { searchInput },
      }),
      providesTags: ["Transactions"],
    }),
    getGeography: build.query({
      query: () => ({
        url: `${BASE_URL}client/geography`,
        method: "GET",
      }),
      providesTags: ["Geography"],
    }),
    getSales: build.query({
      query: ( storeId ) => ({
        url: `${BASE_URL}sales/sales/${storeId}`,
        method: "GET",
      }),
      providesTags: ["Sales"],
    }),
    getAdmins: build.query({
      query: () => ({
        url: `${BASE_URL}management/admins`,
        method: "GET",
      }),
      providesTags: ["Admins"],
    }),
    getUserPerformance: build.query({
      query: (id) => ({
        url: `${BASE_URL}management/performance/${id}`,
        method: "GET",
      }),
      providesTags: ["Performance"],
    }),
    getDashboard: build.query({
      query: (storeId) => ({
        url: `${BASE_URL}general/dashboard/${storeId}`,
        method: 'GET'
      }),
      providesTags: ["Dashboard"],
    }),
    getInfo: build.query({
      query: (storeId) => ({
        url: `${BASE_URL}general/overallinfo/${storeId}`,
        method: 'GET'
      }),
      providesTags: ["Info"],
    }),
    putOverallStats : build.mutation({
      query: (data) => ({
        url: `${BASE_URL}general/overall`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Overall"],
    }),
    getStores: build.query({
      query: (userId) => ({
        url: `${BASE_URL}management/stores/${userId}`,
        method: 'GET'
      }),
      providesTags: ["Stores"],
    }),
    getProductsInfo: build.query({
      query: (storeId) => ({
        url: `${BASE_URL}client/productsinfo/${storeId}`,
        method: 'GET'
      }),
      providesTags: ["ProductsInfo"],
    }),
    getScanInfo: build.query({
      query: (storeId) => ({
        url: `${BASE_URL}client/scaninfo/${storeId}`,
        method: 'GET'
      }),
      providesTags: ["ScanInfo"],
    }),
    getDeleteInfo: build.query({
      query: (storeId) => ({
        url: `${BASE_URL}client/deleteinfo/${storeId}`,
        method: 'GET'
      }),
      providesTags: ["DeleteInfo"],
    }),

    //pilotage
    getOrders: build.query({
      query: ( storeId ) => ({
        url: `${BASE_URL}pilotage/orders/${storeId}`,
        method: "GET",
      }),
      providesTags: ["Orders"],
    }),
    getPaid: build.query({
      query: ( storeId ) => ({
        url: `${BASE_URL}pilotage/paid/${storeId}`,
        method: "GET",
      }),
      providesTags: ["Paid"],
    }),
    getCurrentStore: build.query({
      query: ( storeId ) => ({
        url: `${BASE_URL}socket/${storeId}`,
        method: "GET",
      }),
      providesTags: ["Socket"],
    }),

// logs
    postAuth: build.mutation({
      query: (data) =>({
        url: `${BASE_URL}auth`,
        method: "POST",
        data: data
      }),
      invalidatesTags: ["Auth"]
    }),

    postRegister: build.mutation({
      query: (data) =>({
        url: `${BASE_URL}auth/register`,
        method: "POST",
        data: data
      }),
      invalidatesTags: ["Register"]
    }),

    getLogout: build.mutation({
      query: (userId) => ({
        url: `auth/logout/${userId}`,
        method: "POST",
      }),
      invalidatesTags: ["Logout"],
    }),

    // Import Export 
    getProducts : build.mutation ({
      query: (data) => ({
        url: `importexport/import1`,
        method: "POST",
        data: data
      }),
      invalidatesTags: ["Import"],
    }),

    export : build.mutation ({
      query: (storeId) => ({
        url: `${BASE_URL}importexport/export/${storeId}`,
        method: "GET",
      }),
      invalidatesTags: ["Export"],
    }),
    getTab: build.mutation({
      query: ({storeId, start, end}) => ({
        url: `${BASE_URL}importexport/test/${storeId}/${start}/${end}`,
        method: "GET",
        // params: {startDate, endDate},
      }),
      providesTags: ["Tab"],
    }),
    // getImages: build.query({
    //   query: () => ({
    //     url: `${BASE_URL}addImage`,
    //     method: "GET",
    //     params: { searchInput },
    //   }),
    //   providesTags: ["Transactions"],
    // }),
    
  }),
})

export const {
  useGetUserQuery,
  // useGetProductsQuery, en commentaire car sinon 2 fonctions getProducts avec celle de l'import
  useGetCustomersQuery,
  useGetTransactionsQuery,
  useGetGeographyQuery,
  useGetSalesQuery,
  useGetAdminsQuery,
  useGetUserPerformanceQuery,
  useGetDashboardQuery,
  useGetInfoQuery,
  usePutOverallStatsMutation,
  useGetStoresQuery,
  useGetProductsInfoQuery,
  useGetScanInfoQuery,
  useGetDeleteInfoQuery,
  useGetOrdersQuery,
  useGetPaidQuery,
  useGetCurrentStoreQuery,
  // auth log out
  usePostAuthMutation,
  usePostRegisterMutation,
  useGetLogoutMutation,
  //import export
  useGetProductsMutation,
  useExportMutation,
  useGetTabMutation
} = api;
