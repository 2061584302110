import React, { useState, useEffect, useMemo } from "react";
import { Box, useTheme, Typography, Button} from "@mui/material";
import { useGetPaidQuery } from "state/api";
import { useSelector } from 'react-redux';
import { formatDistanceToNow } from 'date-fns';
import socketIOClient from 'socket.io-client';
import { MaterialReactTable } from 'material-react-table';
import Modal from "./Modal";


const OrderPayées = () => {
  const theme = useTheme();

  const storeId = useSelector((state) => state.global.store._id);

  const [ordersData, setOrdersData] = useState(null);

  const {data, error, isSuccess, refetch} = useGetPaidQuery(storeId)

  const [popups, setPopups] = useState([]);

  const closePopup = (popupId) => {
    setPopups((prevPopups) => {
      const updatedPopups = [...prevPopups];
      updatedPopups[popupId] = { ...updatedPopups[popupId], isOpen: false };
      return updatedPopups;
    })
  };

  const soundPlayed = (popupId) => {
    setPopups((prevPopups) => {
      const updatedPopups = [...prevPopups];
      updatedPopups[popupId] = { ...updatedPopups[popupId], popupSoundPlayed: true };
      return updatedPopups;
    });
  };

  useEffect(() => {
          refetch();
          if(data)
          {
            setOrdersData([...data].reverse())
          }
        },[data])

      
          useEffect(() => {
            // const socket = socketIOClient('https://back2back-rabvfmzrtq-od.a.run.app', { //BACKOFFICE BACKEND TEST
            //   keepAlive: true
            // });
            // const socket = socketIOClient("http://localhost:80", {
            //   keepAlive: true,
            // });
            const socket = socketIOClient(process.env.REACT_APP_BACKEND_URL, { // BACKOFFICE BACKEND LIVE
            keepAlive: true,
          });
            setInterval(() => {
              socket.emit('keepAlive');
            }, 30000); 
          socket.on('keepAliveResponse', () => {
            console.log('Connected');
          });
    
          socket.on('connect', () => {
            console.log('Connected to the Socket.IO server');
          });

          socket.on(`Verification ${storeId}`, (change) => {
            const newPopup = {
              id: change._id,
              price: change.itemsPrice,
              isOpen: true,
              popupSoundPlayed: false,
              orderItems: change.orderItems.map((item) => ({
                qty: item.Qty,
                name: item.name,
                image: item.image,
              })),
              orderItemsLength: change.orderItems.length,
              paidAt: change.paidAt,
              promo : change.promo
            };
            
            setPopups((prevPopups) => {
              const existingPopup = prevPopups.find((popup) => popup.id === newPopup.id);
          
              if (!existingPopup) {
                return [newPopup, ...prevPopups];
              } else {
                return prevPopups;
              }
            });
          });
      
          socket.on('disconnect', () => {
            console.log('Disconnected from the Socket.IO server');
          });
    
          socket.on(`PaidChanged ${storeId}`, (newOrder) => {
            setOrdersData((prevOrdersData) => {
              const updatedData = [newOrder, ...prevOrdersData];
              return updatedData;
            });
          });


          
          return () => {
            socket.disconnect();
          };
        

        }, [data, error, isSuccess, ordersData])
        console.log(ordersData)

        const columns = useMemo(
          () => [
            {
              accessorKey: '_id',
              header: 'ID',
              size: 50,
            },
            // {
            //   accessorKey: 'paidAt',
            //   header: 'Purchase Time',
            //   Cell: ({ row }) => {
            //     const date = new Date(row.original.paidAt);
            //     console.log(row.original.paidAt, date)
            //     if (isNaN(date)) {
            //       return <span></span>;
            //     }
            //     const finak = formatDistanceToNow(date)
            //     console.log(finak)
            //     return <span>{formatDistanceToNow(date)}</span>;
            //   },
            // },
            {
              accessorKey: 'paidAt',
              header: 'Heure d\'achat',
              Cell: ({ row }) => {
                const date = new Date(row.original.paidAt);
                if (isNaN(date)) {
                  return <span></span>;
                }
                const formattedDate = date.toLocaleString(undefined, {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                });
            
                return <span>{formattedDate}</span>;
              },
            },
            {
              header: 'Nombre d\'articles',
              Cell: ({ row }) => {
                const totalQuantity = row.original.orderItems.reduce(
                  (total, item) => total + item.Qty,
                  0
                );
                return <span>{totalQuantity}</span>;
              },
            },
            {
              accessorKey: 'itemsPrice',
              header: 'Prix total en €',
              Cell: ({ row }) => <span>{`${row.original.itemsPrice} €`}</span>,
            },
          ],
          [],
        );

  return (
    <Box m="1.5rem 2.5rem">
      {/* <Header title="TRANSACTIONS" subtitle="Liste complète de toutes les transactions" /> */}

      {popups.filter((popup) => popup.isOpen).length > 0 && (
          <>
               <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                minHeight: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >

              <div style={{
                width: "clamp(50%, 680px, 90%)",
                minHeight: "450px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0 0 0 100vmax rgb(0 0 0/80%)",
                position: "fixed",
                zIndex : 10,
              }}> 

              
              <div
                className="pastille"
                style={{
                  position: "absolute",
                  top: "-6.3rem",
                  //left: "-30px",
                  zIndex: "16",
                  padding: "0.6rem 1.8rem",
                  color: "black",
                  background : "linear-gradient(109.6deg, rgb(217, 250, 158) 11.2%, rgb(128, 251, 174) 91.1%)",
                  borderRadius: "100px",
                  
                }}
              >
                <p style={{fontSize :"2.3rem", margin: "0"}}>
                  {popups.filter((popup) => popup.isOpen).length}
                </p>

              </div>
              {popups.map((popup, index) =>
                popup.isOpen ? (
                    <Modal
                      key={index}
                      handleClose={() => closePopup(index)}
                      id={popup.id}
                      popupId={index}
                      orderItems={popup.orderItems}
                      orderItemsLength={popup.orderItemsLength}
                      price={popup.price}
                      paid={popup.paidAt}
                      popupSoundPlayed={popup.popupSoundPlayed}
                      soundPlayed={() => soundPlayed(index)}
                      promo = {popup.promo}

                      
                    />
                    
                ) : null
              )}
        </div>
        </div>
        </>
        )}

      <Box
        height="80vh"
        sx={{
          "& .MuiPaper-root" : {
            border: '1px solid #F0EAEA',
            boxShadow : 'none'
          },
          "& .MuiToolbar-root" : {
           backgroundColor : "#ffffff",
          },
          "& .MuiTableRow-root" : {
            backgroundColor : "#ffffff"
          },
          "& .MuiTableRow-root.Mui-TableBodyCell-DetailPanel" : {
            // backgroundColor : theme.palette.primary[600]
            backgroundColor : "#FFF8B6"
          },
          "& .MuiTableCell-root" : {
            color : "black",
          },
          "& .MuiButtonBase-root": {
            color : "black",
          },
          "& .MuiInputBase-input": {
            // backgroundColor : theme.palette.background.alt
            backgroundColor : "#eeeeee",
            color : 'black'
          },
          "& .MuiSelect-select.MuiTablePagination-select" : {
            display: 'none'
          },
          "& .MuiTableRow-root:hover": {
            // backgroundColor: "#7f11e0"
          }
        }}
      >
      {ordersData && (
      <div style={{overflow: "auto"}}>
        <div style={{width: '100%', display: 'table', tableLayout: 'fixed'}}>

          <MaterialReactTable
          columns={columns}
          data={ordersData}
          // searchFieldStyle={{
          //   "& .MuiInputBase-input": {
          //     backgroundColor: "#7f11e0", // Change this to your desired grey color
          //   },
          // }}
          // sx={{
          //   "& .MuiTableRow-root:hover": {
          //     backgroundColor: "#7f11e0"
          //   }
          // }}
          renderDetailPanel={({ row }) => (
            <Box
              sx={{
                display: 'grid',
                margin: 'auto',
                gridTemplateColumns: '1fr 1fr',
                width: '100%',
              }}
            >
              <Box>
                <Typography sx={{ fontWeight: 'bold' }}>Panier : </Typography>
                {row.original.orderItems.map((item) => (
                  <Box key={item._id} sx={{ marginTop: '20px', display: 'flex', alignItems: 'center', color: '#000000'}}>
                    <img
                      alt="produit"
                      height={50}
                      src={item.image}
                      loading="lazy"
                      style={{ borderRadius: '20%', marginRight: '10px' }}
                    />
                    <div>
                      <Typography>Nom: {item.name}</Typography>
                      <Typography sx={{ fontWeight: 'bold' }}>Quantité: {item.Qty}</Typography>
                      <Typography>Prix article: {item.price} €</Typography>
                    </div>
                  </Box>
                ))}
              </Box>
          
              <Box sx={{ marginTop: '10px' }}>
                <Typography>Prix total: {row.original.itemsPrice} €</Typography>
                <Typography>Promotions: {row.original.promoPrice} €</Typography>
                <Typography>___________________</Typography>
                <Typography sx={{ fontWeight: 'bold' }}>Prix final: {row.original.itemsPrice} €</Typography>
                {/* <Typography>.</Typography>
                <Typography>.</Typography>
                <Typography>.</Typography>
                <Button>Rembourser</Button> */}
              </Box>
            </Box>
          )}
        />
      </div>
    </div>
    )}
      </Box>
    </Box>
  );
};

export default OrderPayées;