import React, { useState, useEffect} from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  Rating,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useGetOrdersQuery } from "state/api";
import { useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import { formatDistanceToNow, differenceInMinutes, formatRelative} from "date-fns";
import { fr } from 'date-fns/locale';
import { MoreHoriz, Check} from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Modal from "./Modal";

const Order = ({ _id, item, price, createdAt, isPaid }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  // const elapsedTime = createdAt
  //   ? formatDistanceToNow(new Date(createdAt), { addSuffix: false })
  //   : "";
  const elapsedTime = createdAt
  ? formatRelative(new Date(createdAt), new Date(), { locale: fr })
  : "";
  const circleColor = isPaid ? "#27D935" : "hsl(60, 100%, 37%)";
  const circleIcon = isPaid ? <Check /> : <MoreHoriz />;

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.primary[100],
        borderRadius: "0.55rem",
        overflow: "visible",
        position: "relative",
        border: "1px solid #F0EAEA",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "-8px",
          right: "-5px",
          width: "28px",
          height: "28px",
          borderRadius: "50%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid #F0EAEA",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "19px",
            height: "19px",
            borderRadius: "50%",
            backgroundColor: circleColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "7px",
          }}
        >
          {circleIcon}
        </div>
      </div>

      <CardContent>
        <Typography
          sx={{ fontSize: 14 }}
          color={theme.palette.secondary[700]}
        ></Typography>
        <Box
          sx={{
            display: "inline-flex",
            background:
              "linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%)",
            p: "0.3rem",
            borderRadius: "15rem",
            alignItems: "center",
          }}
        >
          <AccessTimeIcon sx={{ color: theme.palette.secondary.main }} />
          <Typography
            sx={{ ml: "0.5rem", color: theme.palette.secondary.main }}
          >
            {elapsedTime}
          </Typography>
        </Box>
        <Typography
          sx={{ mt: "0.5rem", mb: "0.5rem" }}
          color={theme.palette.secondary[400]}
        >
          {/* {temp} */}
          {item
            ? `# Articles : ${item.reduce(
                (total, item) => total + item.Qty,
                0
              )}`
            : ""}
        </Typography>

        <Typography sx={{ mb: "1.5rem" }} color={theme.palette.secondary[400]}>
          {Number(price).toFixed(2)} €
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="primary"
          size="small"
          onClick={() => setIsExpanded((prevState) => !prevState)}
          sx={{ color: theme.palette.secondary.main }}
        >
          Voir le panier
        </Button>
      </CardActions>
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        sx={{
          // color: theme.palette.neutral[300],
        }}
      >
        <CardContent>
          <Box>
            {/* <Typography>{_id}</Typography> */}
            {/* <Typography sx={{color : theme.palette.secondary.main}}>Order Items: </Typography> */}
            {item?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {item && item.image && (
                  <img
                    alt="produit"
                    height={50}
                    src={item.image}
                    loading="lazy"
                    style={{
                      borderRadius: "20%",
                      marginRight: "10px",
                      border: "1px solid #F0EAEA",
                    }}
                  />
                )}
                <div>
                  <Typography sx={{ color: theme.palette.secondary.main }}>
                    Produit: {item?.name}
                  </Typography>
                  <Typography>Quantité: {item?.Qty}</Typography>
                  <Typography>Prix: {item?.price} €</Typography>
                </div>
              </Box>
            ))}
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
};

const OrdersEnCours = () => {
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const storeId = useSelector((state) => state.global.store._id);
  const { data, isLoading, error, isSuccess, refetch } = useGetOrdersQuery(
    storeId,
    { subscribe: false, forceRefetch: true }
  );
  const [ordersData, setOrdersData] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const now = new Date();
  const theme = useTheme();
  const [popups, setPopups] = useState([]);




  const closePopup = (popupId) => {
    setPopups((prevPopups) => {
      const updatedPopups = [...prevPopups];
      updatedPopups[popupId] = { ...updatedPopups[popupId], isOpen: false };
      return updatedPopups;
    })
  };

  const soundPlayed = (popupId) => {
    setPopups((prevPopups) => {
      const updatedPopups = [...prevPopups];
      updatedPopups[popupId] = { ...updatedPopups[popupId], popupSoundPlayed: true };
      return updatedPopups;
    });
  };

  var orders;

  useEffect(() => {
    refetch();
    if (data) {
      setOrdersData(data);
    }
  }, [data]);

  useEffect(() => {
    // const socket = socketIOClient("https://back2back-rabvfmzrtq-od.a.run.app", { //BACKOFFICE BACKEND TEST
    //   keepAlive: true,
    // });
    // const socket = socketIOClient("http://localhost:80", {
    //   keepAlive: true,
    // });
    const socket = socketIOClient(process.env.REACT_APP_BACKEND_URL, { // BACKOFFICE BACKEND LIVE
      keepAlive: true,
    });
   
    
    setInterval(() => {
      socket.emit("keepAlive");
    }, 30000);
    if (error && !data) {
      console.log(error);
    }
    socket.on("keepAliveResponse", () => {
      console.log("Connected");
    });

    socket.on("connect", () => {
      // console.log("Connected to the Socket.IO server");
      setIsConnected(true);
    });

    socket.on(`OrderChanged ${storeId}`, (change) => {
      console.log(change)
      setOrdersData((prevOrdersData) => {
        const updatedData = JSON.parse(JSON.stringify(prevOrdersData));
        console.log(updatedData)
        Object.entries(change.updateDescription.updatedFields).forEach(
          ([fieldPath, value]) => {
            const keys = fieldPath.split(".");

            const documentKey = change.documentKey;
            const matchingDocument = updatedData.find(
              (document) => document._id === documentKey._id
            );

            if (matchingDocument) {
              console.log(matchingDocument)
              let currentObj = matchingDocument;
              keys.forEach((key, index) => {
                if (index === keys.length - 1) {
                  currentObj[key] = value;
                } else {
                  currentObj = currentObj[key];
                }
              });
              matchingDocument.itemsPrice = change.fullDocument.itemsPrice
              matchingDocument.orderItems = change.fullDocument.orderItems
              console.log(change.fullDocument);

              // if(matchingDocument.verification === true)
              // { 
              //   const newPopup = {
              //   id: matchingDocument._id,
              //   price: matchingDocument.itemsPrice,
              //   isOpen: true,
              //   popupSoundPlayed: false,
              //   orderItems: matchingDocument.orderItems.map((item) => ({
              //     qty: item.Qty,
              //     name: item.name,
              //     image: item.image,
              //   })),
              //   orderItemsLength: matchingDocument.orderItems.length,
              //   paidAt: matchingDocument.paidAt,
              // };
              
              // setPopups((prevPopups) => {
              //   const existingPopup = prevPopups.find((popup) => popup.id === newPopup.id);
            
              //   if (!existingPopup) {
              //     return [newPopup, ...prevPopups];
              //   } else {
              //     return prevPopups;
              //   }
              // });}
            } else {
            }
          }
        );
        return updatedData;
      });
    });

    socket.on(`NewOrderCreated ${storeId}`, (newOrder) => {
      setOrdersData((prevOrdersData) => {
        const updatedData = [newOrder, ...prevOrdersData];
        return updatedData;
      });
    });


    socket.on(`Verification ${storeId}`, (change) => {
      console.log("LMKNGRJIERNJTRGRTKNGELEKRRKLGLRKJKKLGLKMLKGRKMHMKLGRKKKLNTHKO")
      console.log(change)
      const newPopup = {
        id: change._id,
        price: change.itemsPrice,
        isOpen: true,
        popupSoundPlayed: false,
        orderItems: change.orderItems.map((item) => ({
          qty: item.Qty,
          name: item.name,
          image: item.image,
        })),
        orderItemsLength: change.orderItems.length,
        paidAt: change.paidAt,
        promo : change.promo
      };
      
      setPopups((prevPopups) => {
        const existingPopup = prevPopups.find((popup) => popup.id === newPopup.id);
    
        if (!existingPopup) {
          return [newPopup, ...prevPopups];
        } else {
          return prevPopups;
        }
      });
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from the Socket.IO server");
      setIsConnected(false);
    });

    return () => {
      socket.disconnect();
    };


  }, [data, error, isSuccess, ordersData]);

  // console.log(popups)
  // console.log(popups.isOpen)

  useEffect(() => {
    console.log(ordersData)
  }, [ordersData])
  if (ordersData && Object.keys(ordersData).length > 0) {
    console.log(ordersData)
    const ordersById = Object.values(ordersData).reduce(
      (acc, { _id, orderItems, itemsPrice, createdAt, temp, isPaid }) => {
        if (!acc[_id]) {
          acc[_id] = {
            _id,
            itemsPrice,
            createdAt,
            temp,
            isPaid,
            items:
              orderItems &&
              orderItems.map(({ name, Qty, price, image }) => ({
                name,
                Qty,
                price,
                image,
              })),
          };
        } else {
          acc[_id].items = [
            ...acc[_id].items,
            ...orderItems.map(({ name, Qty, price, image }) => ({
              name,
              Qty,
              price,
              image,
              createdAt,
              temp,
              isPaid,
            })),
          ];
        }
        return acc;
      },
      {}
    );
    orders = Object.values(ordersById);
  }
  return (
    <>
      <Box>
      {popups.filter((popup) => popup.isOpen).length > 0 && (
          <>
               <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                minHeight: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >

              <div style={{
                width: "clamp(50%, 680px, 90%)",
                minHeight: "450px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0 0 0 100vmax rgb(0 0 0/80%)",
                position: "fixed",
                zIndex : 10,
              }}> 

              
              <div
                className="pastille"
                style={{
                  position: "absolute",
                  top: "-6.3rem",
                  //left: "-30px",
                  zIndex: "16",
                  padding: "0.6rem 1.8rem",
                  color: "black",
                  background : "linear-gradient(109.6deg, rgb(217, 250, 158) 11.2%, rgb(128, 251, 174) 91.1%)",
                  borderRadius: "100px",
                  
                }}
              >
                <p style={{fontSize :"2.3rem", margin: "0"}}>
                  {popups.filter((popup) => popup.isOpen).length}
                </p>

              </div>
              {popups.map((popup, index) =>
                popup.isOpen ? (
                    <Modal
                      key={index}
                      handleClose={() => closePopup(index)}
                      id={popup.id}
                      popupId={index}
                      orderItems={popup.orderItems}
                      orderItemsLength={popup.orderItemsLength}
                      price={popup.price}
                      paid={popup.paidAt}
                      popupSoundPlayed={popup.popupSoundPlayed}
                      soundPlayed={() => soundPlayed(index)}
                      promo = {popup.promo}
                    />
                ) : null
              )}
        </div>
        </div>
        </>
        )}

      
        {data || !isLoading ? (
          <>
            <Typography
              mt="35px"
              variant="h4"
              sx={{ color: "black", fontWeight: "bold" }}
            >
              Commencé il y a moins de 5 minutes ↓
            </Typography>
            <Box
              mt="20px"
              display="grid"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              justifyContent="space-between"
              rowGap="20px"
              columnGap="1.33%"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {orders &&
                orders
                  .filter(({ createdAt }) => {
                    const elapsedTimeMinutes = createdAt
                      ? differenceInMinutes(now, new Date(createdAt))
                      : null;
                    return (
                      elapsedTimeMinutes !== null && elapsedTimeMinutes <= 5
                    );
                  })
                  .map(
                    ({ _id, itemsPrice, items, createdAt, temp, isPaid }) => (
                      <Order
                        key={_id}
                        _id={_id}
                        price={itemsPrice}
                        item={items}
                        createdAt={createdAt}
                        temp={temp}
                        isPaid={isPaid}
                      />
                    )
                  )}
            </Box>

            <Typography
              mt="35px"
              variant="h4"
              sx={{ color: "black", fontWeight: "bold" }}
            >
              Commencé il y a moins de 30 minutes ↓
            </Typography>
            <Box
              mt="20px"
              display="grid"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              justifyContent="space-between"
              rowGap="20px"
              columnGap="1.33%"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {/* {orders && orders.map(({ _id, itemsPrice, items, createdAt, temp, isPaid }) => (
        <Order key={_id} _id={_id} price={itemsPrice} item={items} createdAt={createdAt} temp={temp} isPaid={isPaid} />
      ))} */}
              {orders &&
                orders
                  .filter(({ createdAt }) => {
                    const elapsedTimeMinutes = createdAt
                      ? differenceInMinutes(now, new Date(createdAt))
                      : null;
                    return (
                      elapsedTimeMinutes !== null &&
                      elapsedTimeMinutes > 5 &&
                      elapsedTimeMinutes <= 30
                    );
                  })
                  .map(
                    ({ _id, itemsPrice, items, createdAt, temp, isPaid }) => (
                      <Order
                        key={_id}
                        _id={_id}
                        price={itemsPrice}
                        item={items}
                        createdAt={createdAt}
                        temp={temp}
                        isPaid={isPaid}
                      />
                    )
                  )}
            </Box>

            <Typography
              mt="35px"
              variant="h4"
              sx={{ color: "black", fontWeight: "bold" }}
            >
              Il y a plus de 30 minutes ↓
            </Typography>
            <Box
              mt="20px"
              display="grid"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              justifyContent="space-between"
              rowGap="20px"
              columnGap="1.33%"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {orders &&
                orders
                  .filter(({ createdAt }) => {
                    const elapsedTimeMinutes = createdAt
                      ? differenceInMinutes(now, new Date(createdAt))
                      : null;
                    return (
                      elapsedTimeMinutes !== null && elapsedTimeMinutes > 30
                    );
                  })
                  .map(
                    ({ _id, itemsPrice, items, createdAt, temp, isPaid }) => (
                      <Order
                        key={_id}
                        _id={_id}
                        price={itemsPrice}
                        item={items}
                        createdAt={createdAt}
                        temp={temp}
                        isPaid={isPaid}
                      />
                    )
                  )}
            </Box>
          </>
        ) : (
          <>Loading...</>
        )}
      </Box>
    </>
  );
};

export default OrdersEnCours;