import {motion} from "framer-motion";
import React, {useRef, useEffect, useState} from 'react'
import notification from "../assets/notification.mp3"
import { Backdrop } from "@mui/material";
import {Box, Button, Typography,} from "@mui/material";
// import PikkoMaintenance from "../assets/PikkoMaintenance.png"

function Modal({ id, handleClose, popupId, price,orderItems,orderItemsLength, paid, popupSoundPlayed, soundPlayed, login = false, promo}) {

  const audioPlayer = useRef(null);
  const totalQuantity = orderItems && orderItems.reduce((total, item) => total + item.qty, 0);

  useEffect(() => {
    if(!popupSoundPlayed)
  {    
  if (audioPlayer.current) {
      audioPlayer.current.currentTime = 0;
      audioPlayer.current.play();
      soundPlayed(popupId)
    }
  }
  }, [popupId,popupSoundPlayed]);
  // playOn()
  const paidTemp = new Date(paid)
  const paidAt = paidTemp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  const handleCloseModal = () => {
    handleClose(popupId);
   };

  const dropIn = {
    hidden: {
      y: "50vh",
      opacity : 0
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500
      }
    },
    exit: {
      y: "100vh",
      opacity : 0
    },
  };


 return ( 
  <>
  {!login ? (
  <>
    <audio ref={audioPlayer} src={notification} />
    <motion.div
      // onClick={(e) => e.stopPropagation()}
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      style={{
        width: 'clamp(51%, 700px, 90%)',
        minHeight: '500px',
        height: 'fit-content',
        margin: 'auto',
        marginTop: '3rem',
        padding: '1rem',
        borderRadius: '19px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        color: 'black',
        // border : "solid 8px #F1E526",
        zIndex: 12,
        gap: '10px',
        position: 'fixed',
        // Other styles...
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add your box shadow here
      }}>
      {/* <div>ID: {id}</div>
      <div >Order Items:</div> */}
      <h2 style={{ fontSize: '5rem', margin: '0', fontWeight: 'bold', fontFamily: 'sans-serif' }}>{price} €</h2>

      {/* {promo !== "" && (
      <Typography variant="h4" color="warning" marginTop="2rem">
        PROMO
      </Typography>
      )} */}

      <h3 style={{
        fontSize: '1.5rem', margin: '0', fontFamily: 'sans-serif', fontWeight: 'inherit', marginTop: '-1rem'
      }}> {orderItemsLength > 1 ? <>Total articles : {totalQuantity} </> : <># articles : {totalQuantity}</>}
      </h3>

      <button onClick={handleCloseModal} style={{
        padding: '0.6rem 2rem',
        borderRadius: '100px',
        background: 'linear-gradient(140deg,#e1ff26 15%,#ffcf26)',
        border: 'none',
        fontWeight: '600',
        marginTop: '110px',
        position: 'absolute',
        zIndex: '999'
      }}>
        Fermer
      </button>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '300px',
        flexWrap: 'wrap',
        width: '100%',
      }}>

        {orderItems.slice(0, 6).map((item, index) => (
          <>
            {orderItemsLength < 3 ?

              <div key={index} style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '1.5rem',
                gap: '1rem',
                minWidth: '40%',
                textAlign: 'center'
              }}>
                {/* <img style={{width : "auto", height : "5rem", border : "solid 8px #F1E526",  borderRadius: "8px", padding: "2px"}} src={item.image} alt={item.name} />*/}


                <div
                  style={{
                    width: '160px',
                    height: '160px',
                    border: 'solid 8px #F1E526',
                    borderRadius: '19px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10px',
                    filter: 'drop-shadow(0 0 0.75rem #D4D4D4)'
                  }}
                >
                  <img
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'cover',
                    }}
                    src={item.image}
                    alt={item.name}
                  />
                </div>


                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>x {item.qty}</div>
                  <div>{item.name}</div>
                </div>
              </div>

              :
              <div key={index} style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '1.5rem',
                gap: '1rem',

                width: '25%',
                textAlign: 'center',
              }}>
                <div>
                  <div
                    style={{
                      width: '160px',
                      height: '160px',
                      border: 'solid 8px #F1E526',
                      borderRadius: '19px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '10px',
                      filter: 'drop-shadow(0 0 0.75rem #D4D4D4)',
                      background: 'white'

                    }}
                  >
                    <img
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'cover',
                      }}
                      src={item.image}
                      alt={item.name}
                    />
                  </div>
                  {/* <img style={{width : "auto", height : "5rem"}} src={item.image} alt={item.name} /> */}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>x {item.qty}</div>
                  <div>{item.name}</div>
                </div>
              </div>
            }
          </>
        ))}
      </div>
      {/*orderItemsLength > 3 ? <><span>And more...</span></> : <><div style={{padding: "15px"}}></div></>*/}
      <div style={{ textAlign: 'center', position: 'absolute', left: '1.8rem' }}>
        <p style={{ marginBottom: '0' }}>Payé à </p>
        <p style={{ fontSize: '1.5rem', marginTop: '0' }}>{paidAt}</p>
      </div>

      <button onClick={handleCloseModal} style={{
        padding: '0.6rem 2rem',
        borderRadius: '100px',
        background: 'linear-gradient(140deg,#e1ff26 15%,#ffcf26)',
        border: 'none',
        fontWeight: '600',
        marginTop: '10px',
      }}>
        Fermer
      </button>
      {/* <button onClick={playOn}>test</button> */}
    </motion.div>
  </>
  ) : (
    <>
      <motion.div
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        style={{
          width: 'clamp(30%, 200px, 60%)',
          minHeight: '300px',
          height: 'fit-content',
          margin: 'auto',
          marginTop: '3rem',
          padding: "1rem",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          color: "black",
          border: "solid 8px #B42318",
          zIndex: 12,
          gap: "10px",
          position: "fixed",
          justifyContent: "space-between", 
          textAlign: "center", 

        }}
      >
        <div>
          <Typography variant="h3" fontWeight="bold">
            Session expirée
          </Typography>
          <Typography variant="h5" marginTop = "2rem">
            Veuillez vous reconnecter pour accéder à l'outil de pilotage
          </Typography>
          {/* <Box
            className ="Maint"
            component="img"
            alt="Maint"
            src={PikkoMaintenance}
            width="4rem"
            height="auto"
            sx={{ objectFit: 'contain' }}
          /> */}
        </div>
        <div style={{ alignSelf: "center" }}>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Se reconnecter
        </Button>
      </div>
      </motion.div>
    </>

    )}
  </>
  )
}
export default Modal